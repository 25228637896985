@font-face {
    font-family: 'Disket Mono';
    src: url('./fonts/Disket-Mono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Disket Mono';
    src: url('./fonts/Disket-Mono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Permanent Marker';
    src: url('./fonts/PermanentMarker.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fit';
    src: url('./fonts/Fit-Regular-Testing.woff') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.glitch {
    position: relative;
    color: #FF0162;
    font-size: 80px;
}

.line {
    &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
    }

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            animation:
            clip 3000ms $i * -300ms linear infinite,
            glitch#{$i} 1000ms random(1000) * -1ms linear infinite;

            @keyframes glitch#{$i} {
                                 0% {
                                     transform: translateX(0);
                                 }
                                 80% {
                                     transform: translateX(0);
                                     color: #fff;
                                 }
                                 85% {
                                     transform: translateX(random(10) - 5px);
                                     color: #4E9A26;
                                 }
                                 90% {
                                     transform: translateX(random(10) - 5px);
                                     color: #AC1212;
                                 }
                                 95% {
                                     transform: translateX(random(10) - 5px);
                                     color: #fff;
                                 }
                                 100% {
                                     transform: translateX(0);
                                 }
                             }
        }
    }
}

@keyframes clip {
    0% {
        clip-path: polygon(
                0 100%,
                100% 100%,
                100% 120%,
                0 120%
        );
    }

    100% {
        clip-path: polygon(
                0 -60%,
                100% -60%,
                100% 0%,
                0 0
        );
    }
}

.lb-container {
  z-index: 100 !important;
}

.lb-header .lb-title span {
  font-size: 13px;
}